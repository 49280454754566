.PersonalRecuiterSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 65px;
}
@media (max-width: 723px) {
  .subtitle {
    max-width: 300px;
    text-align: center;
  }
}
