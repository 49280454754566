.JobListings {
}

.joblisting-table {
  padding: 0.5rem;
}

.job-listings {
  padding: 20px;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.table-header {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-tag {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.status-applied {
  background-color: #E9ECF0;
  color: #2F4858;
}

.status-need_more_info {
  background-color: #E6EEF3;
  color: #33658A;
}

.status-interviewing {
  background-color: #EEF4F8;
  color: #86BBD8;
}

.status-offered {
  background-color: #EEF2EA;
  color: #758E4F;
}

.status-rejected {
  background-color: #FDE9E1;
  color: #F26419;
}

.status-accepted {
  background-color: #EAF0F1;
  color: #55828B;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.dashboard-collapse {
  margin-bottom: 24px;
}

.dashboard-collapse .ant-collapse-header {
  font-size: 16px;
  font-weight: 500;
}

.dashboard-collapse .ant-collapse-header .anticon {
  margin-right: 8px;
}

.dashboard-stats {
  padding: 16px 0;
}

.dashboard-stats .ant-card {
  height: 100%;
  border-radius: 8px;
}

.dashboard-stats .ant-card-head {
  border-bottom: 1px solid #f0f0f0;
  min-height: 48px;
}

.dashboard-stats .ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
  color: #262626;
}

.dashboard-stats .recharts-wrapper {
  margin: 0 auto;
}

.dashboard-stats .recharts-legend-wrapper {
  font-size: 12px;
}

.dashboard-stats .recharts-default-tooltip {
  background-color: rgba(255, 255, 255, 0.95) !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 4px;
  padding: 8px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
