.LandingPage {
  max-width: 1024px;
  margin: auto;
}

.hero-container {
  max-width: 25.57rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hero-display-web {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 40px;
}

.hero-container h1 {
  color: #1d1d1f;
  font-family: ibmplexsans-bold;
  font-size: 2.95rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.95rem; /* 68.234% */
}
@media (max-width: 723px) {
  .hero-container {
    max-width: 25.57rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .hero-display-web {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 40px;
    flex-wrap: wrap;
  }
  .hero-container h1 {
    color: #1d1d1f;
    font-family: ibmplexsans-bold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.95rem; /* 68.234% */
  }
  .subtitle {
    text-align: center;
  }
  img {
    min-width: 100%;
    max-width: 300px;
  }
  button {
    max-width: 300px;
  }
}

.subtitle {
  color: #525252;
  font-family: ibmplexsans-medium;
  font-size: 1.08438rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.cta-deal {
  color: #0050b3;
  font-size: 1.08438rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 25rem;
  margin-right: 1rem;
}

.cta-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}
.cta-buttons button {
  width: 16.4375rem;
  height: 2.75rem;
  flex-shrink: 0;
}
