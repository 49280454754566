.FrustratedJobseekerSection {
  padding-top: 25px;
  padding-bottom: 65px;
}
.frustrated-jobseeker-container {
  display: flex;
  flex-direction: row;
  align-items: top;
  gap: 40px;
  /* margin-left: 3.44rem; */
}
@media (max-width: 723px) {
  .frustrated-jobseeker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
  }
  img {
    min-width: 100%;
    max-width: 300px;
  }

  .subtitle {
    max-width: 300px;
  }
  .main-header {
    max-width: 300px;
  }
  button {
    max-width: 300px;
  }
}
.frustrated-jobseeker-cta {
  width: 28.5rem;
  height: 2.6875rem;
  flex-shrink: 0;
  margin-top: 15px;
  border-radius: 0.25rem;
  background: #09d937;
  color: white;
  font-size: 1.1rem;
  font-family: 'ibmplexsans-medium';
}

/* .main-cta {
 color: #5a5a5a;
text-align: center;
width: 29rem;
font-size: 2.25rem;
font-style: normal;
font-weight: 600;
line-height: normal;
} */
